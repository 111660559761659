<template>
  <div class='main' v-loading="loading">
    <back />
    <div>
      <div style="font-size: 16px; font-weight: bold;margin:20px 0px 20px 30px;">详情信息：</div>
      <div class="container">
        <div class="item">申请单号：{{form.applyNum}}</div>
        <div class="item">商超名称：{{form.applyUserName}}</div>
        <div class="item">商超编号：{{form.shopCode}}</div>
        <div class="item">账户类型：{{form.accountType==1?'支付宝':'银行卡'}}</div>
        <div class="item">真实姓名：{{form.accountName}}</div>
        <div class="item">账号：{{form.accountNum}}</div>
        <div class="item" v-if="form.accountType==2">开户行：{{form.opeBank}}</div>
        <div class="item">提现金额：{{form.applyAmount}}元</div>
        <div class="item">可提现金额：{{form.availableAmount}}元</div>
        <div class="item">申请时间：{{form.createdTime}}</div>
        <div class="item">审核时间：{{form.updatedTime}}</div>
        <div class="item" v-if="form.status==1">转账时间：{{form.receivedTime}}</div>
        <div class="item">状态：{{mapStatus}}</div>
      </div>
      <div class="red" v-if="form.status==2">不通过原因：{{form.auditMsg}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: null,
      loading: false,
      mapStatus: '',
      form: {},
    }
  },
  created() {
    this.id = this.$route.query.id
    console.log(this.id);
    this.getSupplierDetail()
  },
  methods: {
    getSupplierDetail() {
      this.loading = true
      this.$axios.get(this.$api.getPageListDetails + this.id).then((res) => {
        this.form = res.data.result
        this.mapStatus = res.data.result.status == 1 ? '已转账' : res.data.result.status == 2 ? '审核失败' : '转账失败'
        this.loading = false
      }).catch(() => {
        this.loading = false
      });
    },

  },
}
  </script>

  <style scoped lang='scss'>
.main {
  .container {
    display: flex;
    flex-wrap: wrap; /* 允许项目换行 */
    margin-left: 23px;
  }

  .item {
    flex: 0 0 calc(25% - 10px); /* 设置项目的宽度为容器宽度的25%，减去适当的间隙 */
    box-sizing: border-box; /* 确保边框和内边距计算在宽度内 */
    margin: 5px; /* 添加外边距，根据需要调整 */
    margin-bottom: 15px;
    color: #707070;
  }
  .red {
    margin-left: 30px;
    color: red;
  }
}
</style>
  